<template>
  <div class="send_msg">
    <div class="title clearfix">
      <p class='f_left'>{{$t('lang.snedCodeToEmail')}}</p>
      <i
        class="f_right iconfont"
        @click='closeHandleBox'>&#xe702;</i>
    </div>
    <div class="content">
      <div class="address">
        <div class='clearfix'>
          <span class='f_left'>{{$t('lang.snedCodeTo')}}:</span>
          <div class='f_left'>
            <p
              class="f_left email_adres"
              v-for='(item, index) in addressArr'
              :key='index'>
              <span>{{item}}</span>
              <i
                class="el-icon-close"
                @click='removeItem(index)'></i>
            </p>
            <input
              type="text"
              maxlength="225"
              v-model='emailAdres'
              :placeholder="$t('lang.emialAds')"
              @keydown="handleCheckEmail($event, 'checkEmail')"
              @blur="checkEmail">
          </div>
        </div>
      </div>
      <div class="send_des">
        <div class="send_textarea">
          <textarea
            name=""
            id=""
            wrap="hard"
            :placeholder="$t('lang.emailTips')"
            v-model="sendInfoPar.content"></textarea>
        </div>
        <h4>{{selectedItem.name}}</h4>
        <div class="qrcode_box">
          <div
            id="sent-qrbox"
            v-if='isShowQrcode'></div>
        </div>
        <p class='code'>{{selectedItem.code}}</p>
      </div>
    </div>
    <div class="footer">
      <button
        class="btn"
        @click='sendInfo'>{{$t('lang.send')}}</button>
      <button
        class="btn gray"
        @click='closeHandleBox'>{{$t('lang.cancel')}}</button>
    </div>
  </div>
</template>
<script>
import { sendEmail } from '@/service/token'

export default {
  props: {
    selectedItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isShowQrcode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      emailAdres: '',
      addressArr: [],
      sendInfoPar: {
        address: '',
        content: '',
        pic: '',
        code: '',
        name: ''
      },
      isSave: true
    }
  },
  methods: {
    // 关闭弹出窗
    closeHandleBox () {
      this.initParams()
      this.$emit('hideHandleBox', 'sendMsg')
    },
    // 恢复默认参数值
    initParams () {
      this.emailAdres = ''
      this.addressArr = []
      this.sendInfoPar = {
        address: '',
        content: '',
        pic: '',
        code: '',
        name: ''
      }
    },
    // 校验email
    checkEmail () {
      this.emailAdres = this.emailAdres.trim()
      if (this.emailAdres == '') return // email是为空
      if (!this.emailAdres.match(/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,63}$/)) { // 是否符合email规范
        this.$message.error(this.$t('lang.mailAddrTips'))
        return
      };
      this.addressArr.push(this.emailAdres)
      this.emailAdres = ''
    },
    // keydown
    handleCheckEmail (e, event) {
      if (e.keyCode == 13) {
        this[event]()
      }
    },
    // 移除email address
    removeItem (index) {
      this.addressArr.splice(index, 1)
    },
    // 发送信息
    sendInfo () {
      if (this.addressArr.length == 0) {
        this.$message.error(this.$t('lang.mailAddrTips'))
        return
      }
      this.sendInfoPar.pic = $('#sent-qrbox img').attr('src')
      this.sendInfoPar.address = this.addressArr
      this.sendInfoPar.code = this.selectedItem.code
      this.sendInfoPar.name = this.selectedItem.name
      this.sendInfoPar.content = this.sendInfoPar.content.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' ')
      sendEmail(this.sendInfoPar)
        .then(res => {
          if (res.status == 200) {
            if (res.data.errorCode != '0x0') {
              this.$message.error(this.$t('lang.operationFailure'))
            }
          }
        }).catch(() => {
          this.$message({
            message: this.$t('lang.operationFailure'),
            type: 'error'
          })
        })
      this.closeHandleBox() // 关闭发送窗口
    }
  }
}
</script>
<style lang='less' scoped>
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/*滑块*/
::-webkit-scrollbar-thumb {
  background-color: rgb(51, 51, 51);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}
/*滑道*/
::-webkit-scrollbar-track {
  border-radius: 10px;
}
h4,
p {
  margin: 0;
}
.send_msg {
  width: 680px;
  // height: 835px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: 0px 0px 15px #100400;
  background: #252525;
  text-align: left;
  .title {
    color: #fff;
    font-size: 0.2rem;
    font-weight: bold;
    text-indent: 0.2rem;
    height: 60px;
    line-height: 60px;
    background-color: #33ab4f;
    i {
      font-size: 28px;
      font-weight: normal;
      margin-right: 15px;
      // margin-top: -20px;
    }
  }
  .content {
    padding: 40px 50px;
    padding-bottom: 0;
    .address {
      height: 48px;
      border-bottom: 2px solid #333;
      margin-bottom: 20px;
      overflow: auto;
      > div > div {
        margin-left: 7px;
      }
      .email_adres {
        height: 25px;
        margin-right: 5px;
        padding: 2px 2px 2px 10px;
        border: 1px solid #999;
        border-radius: 3px;
        > i {
          padding: 0 3px;
          font-weight: 700;
          cursor: pointer;
        }
      }
      input {
        background: transparent;
        border: none;
        outline: none;
        margin-left: 10px;
      }
    }
    .send_des {
      width: 100%;
      height: 440px;
      overflow-y: auto;
      padding: 10px 20px;
      color: #a1a1a1;
      margin-bottom: 60px;
      box-sizing: border-box;
      border: 2px solid #333;
      .send_textarea {
        width: 100%;
        height: 120px;
        border-bottom: 2px solid #333;
        textarea {
          min-height: 120px;
          width: 100%;
          padding: 0;
        }
      }
      textarea {
        width: 120%;
        padding-right: 20%;
        height: 100%;
        outline: none;
        border-bottom: 1px solid #333;
        font-size: 14px;
        color: #a1a1a1;
        line-height: 25px;
        background: transparent;
        border: none;
        resize: none;
      }
      h4 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 32px;
        color: #fff;
        text-align: center;
        margin: 10px 0;
      }
      .qrcode_box,
      #sent-qrbox {
        width: 160px;
        height: 160px;
        background: #fff;
        margin: 0 auto;
      }
      #sent-qrbox {
        border: 5px solid rgb(255, 255, 255);
      }
      .code {
        color: #33ab4f;
        text-align: center;
        font-size: 32px;
      }
    }
  }
  .footer {
    text-align: center;
    padding-bottom: 40px;
    .btn {
      margin-right: 0.48rem;
      height: 0.4rem;
      width: 1rem;
      border: 1px solid #0f620a;
      background-color: #0c6;
      color: #fff;
      font-size: 12px;
      cursor: pointer;
    }
    .btn.gray {
      border: 1px solid #524c4c;
      background-color: #a0a0a0;
      color: #fff;
      margin-right: 0;
    }
  }
}
</style>
