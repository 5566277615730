<template>
  <el-dialog
    :title='$t("lang.shareTo")'
    :visible.sync="shareVisible"
    width="500px"
    :before-close="handleClose"
    :destroy-on-close='true'>
    <p class='title'>{{$t('lang.userAccount')}}</p>
    <el-select
      v-model="searchValue"
      v-scroll="bindScroll"
      multiple
      filterable
      collapse-tags
      reserve-keyword
      remote
      :remote-method="getUserList"
      :placeholder="$t('lang.shareToeknSelect')"
      @change='changeShareUser'
      @focus="initUserList"
      style="margin-left: 20px;"
      class='user_select'
      popper-class='select_account'>
      <el-option
        v-for="item in userList"
        :key="item.id"
        :label="item.email"
        :value="item.id">
      </el-option>
    </el-select>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        type="primary"
        v-repeat-click='{event:saveEdit}'>{{$t('lang.save')}}</el-button>
      <el-button @click="handleClose">{{$t('lang.cancel')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getUserList, getShareList, saveShare } from '@/service/token'

export default {
  props: {
    shareVisible: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      bindScroll: {
        onScroll: this.handleScroll,
        distance: 10,
        directive: '.el-select-dropdown__wrap.el-scrollbar__wrap'
      },
      userListParams: {
        search: '',
        currentPageNum: 1,
        everyPageNum: 10
      },
      searchValue: [],
      userList: [],
      lastShareUserList: [],
      totalPage: 1
    }
  },
  async created () {
    await this.getShareList()
    this.getUserList()
  },
  methods: {
    // 滚动加载
    handleScroll () {
      if (this.totalPage < this.userListParams.currentPageNum) return
      this.userListParams.currentPageNum++
      this.getUserList(this.userListParams.search)
    },
    // 关闭弹窗
    handleClose (update) {
      this.$emit('closeShareBox', 'shareVisible', false, update)
    },
    // 保存分享
    saveEdit () {
      let willAddUserId = []
      let willRemoveUserId = []
      this.searchValue.map((v, i) => {
        if (!this.lastShareUserList.includes(v)) {
          willAddUserId.push(v)
        }
      })
      this.lastShareUserList.map((v, i) => {
        if (!this.searchValue.includes(v)) {
          willRemoveUserId.push(v)
        }
      })
      let params = {
        willAddUserId,
        willRemoveUserId,
        code: this.code
      }
      saveShare(params).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          this.handleClose('update')
        } else {
          this.$message.error(res.data.errorInfo)
        }
      }).catch(() => {})
    },
    // 获取已分享的用户列表
    async getShareList () {
      getShareList(this.code).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          let selectedIds = []
          this.selectedArr = res.data.errorInfo
          res.data.errorInfo && res.data.errorInfo.map((v, i) => {
            selectedIds.push(v.userId)
          })
          this.searchValue = selectedIds
          this.lastShareUserList = selectedIds
          this.$nextTick(() => {
            this.selectedArr && this.selectedArr.map((v, i) => {
              if ($('.el-select__tags-text').eq(0).html() == v.userId) { // 如果选中的不在当前列表中，找到对应的email，并显示上去
                $('.el-select__tags-text').eq(0).html(v.email)
              }
            })
          })
        } else {
          this.$message.error(res.data.errorInfo)
        }
      }).catch(() => {})
    },
    // 更改分享的用户
    changeShareUser (val) {
      this.$nextTick(() => {
        this.selectedArr && this.selectedArr.map((v, i) => {
          if (val[0] == v.userId) { // 如果选中的不在当前列表中，找到对应的email，并显示上去
            $('.el-select__tags-text').eq(0).html(v.email)
          }
        })
      })
    },
    // 初始化user列表
    initUserList () {
      console.log($('.is-multiple.select_account').css('display'))
      if ($('.is-multiple.select_account').css('display') == 'none') {
        this.userListParams.currentPageNum = 1
        this.userListParams.search = ''
        this.getUserList()
      }
    },
    // 获取user列表
    getUserList (value) {
      if (value && value != this.userListParams.search) {
        this.userListParams.currentPageNum = 1
      }
      if (this.totalPage < this.userListParams.currentPageNum) return
      this.userListParams.search = value || ''
      getUserList(this.userListParams).then(res => {
        if (res.status == '200' && res.data.errorCode == '0x0') {
          this.totalPage = res.data.errorInfo.pages || 1
          if (this.userListParams.currentPageNum == 1) {
            this.userList = res.data.errorInfo.list
          } else {
            this.userList = this.userList.concat(res.data.errorInfo.list)
          }
          this.$nextTick(() => {
            this.selectedArr && this.selectedArr.map((v, i) => {
              if ($('.el-select__tags-text').eq(0).html() == v.userId) { // 如果选中的不在当前列表中，找到对应的email，并显示上去
                $('.el-select__tags-text').eq(0).html(v.email)
              }
            })
          })
        } else {
          this.$message.error(res.data.errorInfo)
        }
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
p {
  margin-bottom: 0;
}
.title {
  margin-bottom: 5px;
}
::v-deep .el-dialog {
  text-align: left;
  .el-dialog__header {
    padding-left: 16px;
    .el-dialog__headerbtn .el-icon-close {
      transform: rotate(45deg);
    }
    .el-dialog__headerbtn .el-icon-close:hover {
      transform: rotate(45deg);
    }
    .el-dialog__headerbtn .el-icon-close:before {
      content: '\E602';
    }
  }
  .el-dialog__body {
    font-size: 16px;
    .el-select {
      width: 100%;
      margin: 0 !important;
    }
  }
  .el-input .el-input__inner {
    border: none;
    background: rgba(68, 68, 68, 1);
    border-radius: 4px;
  }
  .el-dialog__footer {
    text-align: center;
    padding: 24px 0 32px;
  }
  .el-button {
    width: 120px;
    height: 40px;
  }
}
.device_name {
  margin-bottom: 5px;
}
::v-deep .user_select .el-select__input {
  color: #aaa;
}
::v-deep {
  .el-input__inner {
    &::placeholder {
      color: #aaa;
    }
  }
}
</style>

<style lang="less">
.el-select-dropdown.select_account {
  z-index: 2038 !important;
}
</style>
