<template>
  <div class="create_token">
    <div class="title clearfix">
      <p class='f_left'>{{$t(`lang.${type=='edit'?'editToken':'createToken'}`)}}</p>
      <i
        class=" iconfont f_right"
        @click.stop='closeCreateBox'>&#xe702;</i>
    </div>
    <div class="content">
      <div class="create_item">
        <p>{{$t('lang.tokenName')}}</p>
        <el-input
          :placeholder="$t('lang.iptTokenName')"
          :class='{forbid: isExpiration}'
          :disabled="isExpiration"
          v-model.trim='tokenName'
          @blur='checkTokenName'
          maxlength='32'></el-input>
      </div>
      <div class="create_item">
        <p>{{$t('lang.expiration')}}</p>
        <div class='pr'>
          <input
            type="text"
            disabled
            :placeholder="$t('lang.iptExpiration')"
            :value='formatTime(setExpiredTime)'>
          <i class="iconfont data_icon">&#xe61a;</i>
          <div class="date_selection">
            <el-date-picker
              v-model="setExpiredTime"
              :picker-options="pickerDateBeg"
              @change='changeExpiredTime'
              @focus="openExpirationTime"
              align='right'
              type="datetime">
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="create_item">
        <el-checkbox v-model="autoApprove">{{ $t('lang.autoApprove') }}</el-checkbox>
      </div>
      <div class="create_item clearfix">
        <div class="f_left list">
          <p>{{$t('lang.availableList')}}</p>
          <div class="search_box pr">
            <input
              type="text"
              :disabled='isExpiration'
              :class='{forbid: isExpiration}'
              :placeholder="$t('lang.peeridOrName')"
              v-model='availableRPar.condition'
              @keydown='inputVal($event, "getAvailableR")'>
            <i
              class="icon el-icon-search pointer"
              @click='getAvailableR(false)'></i>
          </div>
          <div class="list_box">
            <div class="tr list_title">
              <div class="f_left th">{{$t('lang.pid')}}</div>
              <div class="f_left th">{{$t('lang.deviceName')}}</div>
            </div>
            <div class="list_content">
              <div
                class="tr"
                :class='{gray:index%2,forbid:isExpiration}'
                v-for='(item, index) in listR.available'
                :key='index'
                @click='pitchOpt("available", index)'>
                <div
                  class="f_left checkbox el-icon-check"
                  :class='{ischecked: pitchOn.available[index]}'></div>
                <el-tooltip
                  :content="item?.peerId"
                  placement="bottom"
                  effect="light">
                  <div class="f_left td tds">{{item?.peerId}}</div>
                </el-tooltip>
                <el-tooltip
                  :content="item?.name"
                  placement="bottom"
                  effect="light">
                  <div class="f_left td">{{item?.name}}</div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="f_left btns">
          <div
            class="btn"
            :class='{forbid:isExpiration}'
            @click='addOrRemoveAllR("available", "selected")'>{{$t('lang.addAll')}} &gt;&gt; </div>
          <div
            class="btn"
            :class='{forbid:isExpiration}'
            @click='addOrRemoveR("available", "selected")'> &gt; </div>
          <div
            class="btn btnBlack"
            :class='{forbid:isExpiration}'
            @click='addOrRemoveR("selected", "available")'> &lt;</div>
          <div
            class="btn btnBlack"
            :class='{forbid:isExpiration}'
            @click='addOrRemoveAllR("selected", "available")'> &lt;&lt; {{$t('lang.removeAll')}}</div>
        </div>
        <div class="f_right list">
          <p>{{$t('lang.selectedList')}}</p>
          <div class="search_box pr">
            <input
              type="text"
              :disabled='isExpiration'
              :class='{forbid: isExpiration}'
              :placeholder="$t('lang.peeridOrName')"
              v-model='correlateRPar.condition'
              @keydown='inputVal($event, "getCorrelateR")'>
            <i
              class="icon el-icon-search pointer"
              @click='getCorrelateR(false)'></i>
          </div>
          <div class="list_box">
            <div class="tr list_title">
              <div class="f_left th">{{$t('lang.pid')}}</div>
              <div class="f_left th">{{$t('lang.deviceName')}}</div>
            </div>
            <div class="list_content">
              <div
                class="tr"
                :class='{gray:index%2,forbid:isExpiration}'
                v-for='(item, index) in listR.selected'
                :key='index'
                @click='pitchOpt("selected", index)'>
                <div
                  class="f_left checkbox el-icon-check"
                  :class='{ischecked: pitchOn.selected[index]}'></div>
                <el-tooltip
                  :content="item?.peerId"
                  placement="bottom"
                  effect="light">
                  <div class="f_left td tds">{{item?.peerId}}</div>
                </el-tooltip>
                <el-tooltip
                  :content="item?.name"
                  placement="bottom"
                  effect="light">
                  <div class="f_left td">{{item?.name}}</div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button
          class="btn"
          @click.stop='closeCreateBox'>{{$t('lang.cancel')}}</button>
        <button
          class="btn gray"
          @click.stop='createToken'>{{$t('lang.save')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import Outil from '@/assets/js/utils.js'
import { tokenAnalyze } from '@/assets/js/googleAnalyze/token.js'
import { getCorrelateR, getNotAssociatedR, checkName } from '@/service/token'

export default {
  props: {
    editItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      setExpiredTime: '',
      tokenExpiredTime: '',
      availableRList: [],
      selectList: [],
      listR: {
        available: [],
        selected: []
      },
      pitchOn: {
        available: [],
        selected: []
      },
      searchVal: {
        available: '',
        selected: ''
      },
      availableRPar: {
        code: '',
        condition: ''
      },
      correlateRPar: {
        code: '',
        condition: ''
      },
      flag: {
        add: true,
        update: true
      },
      // 选择过期时间
      pickerDateBeg: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return time.getTime() < new Date(date.year + '-' + date.month + '-' + date.day + ' 00:00:00').getTime()
        }
      },
      // 创建token的参数
      createTokenPar: {
        name: '',
        expireTime: '',
        willAddRIds: '',
        willRemoveRIds: ''
      },
      tokenName: '',
      oldTokenName: '',
      isSave: true,
      bindDevice: [],
      isExpiration: false,
      autoApprove: false
    }
  },
  created () {
    if (this.type == 'edit') {
      this.tokenName = this.editItem.name
      this.oldTokenName = this.editItem.name
      this.setExpiredTime = new Date(this.editItem.expireTime)
      this.tokenExpiredTime = this.editItem.expireTime
      this.autoApprove = this.editItem.autoApprove || false
      if (this.tokenExpiredTime < new Date().getTime()) {
        this.isExpiration = true
      }
      this.getCorrelateR(true)
    }
    this.getAvailableR(true)
  },
  methods: {
    // 输入框输入值
    inputVal (e, key) {
      if (e.keyCode == 13) {
        this[key]()
      }
    },
    // 展开时间日历后去掉now按钮
    openExpirationTime () {
      this.$nextTick(() => {
        $('.el-picker-panel__footer .el-button.el-picker-panel__link-btn.el-button--text.el-button--mini').hide()
      })
    },
    // 获取绑定的R
    getCorrelateR (initVal) {
      // 过期的token不能操作设备
      if (!initVal && this.tokenExpiredTime < new Date().getTime()) return
      this.correlateRPar.code = this.editItem.code
      getCorrelateR(this.correlateRPar).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          let data = res.data
          this.bindDevice = data.errorInfo
          this.listR.selected = this.removeRepetition(this.listR.available, data.errorInfo)
        }
      }).catch(() => {})
    },
    // 获取可用R列表
    getAvailableR (initVal) {
      if (!initVal && this.availableRPar.condition.length < 3) {
        this.$message.error(this.$t('lang.searchDevicePrompt'))
        return
      }
      // 过期的token不能操作设备
      if (this.type == 'edit' && this.tokenExpiredTime < new Date().getTime()) return
      getNotAssociatedR(this.availableRPar).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          let data = res.data
          this.listR.available = this.removeRepetition(this.listR.selected, data.errorInfo)
        }
      }).catch(() => {})
    },
    // 去重
    removeRepetition (otherArr, dataArr) {
      let ids = []
      let reRepetArr = []
      otherArr.map(v => {
        ids.push(v.peerId)
      })
      dataArr.map(v => {
        if (!ids.includes(v.peerId)) {
          reRepetArr.push(v)
        }
      })
      return reRepetArr
    },
    // 关闭创建框
    closeCreateBox (type) {
      this.$emit('hideHandleBox', 'createBox', type)
    },
    // 是否选中R
    pitchOpt (key, index) {
      if (this.isExpiration) return
      var arr = this.pitchOn[key]
      arr[index] = !arr[index]
      this.pitchOn[key] = []
      Array.prototype.push.apply(this.pitchOn[key], arr)
    },
    // 添加或删除R
    addOrRemoveR (key, desKey) {
      if (key == 'available') {
        tokenAnalyze('tokenAddDevice', '/tkm')
      } else {
        tokenAnalyze('tokenRemoveDevice', '/tkm')
      }
      // 过期的token不允许操作设备
      if (this.type == 'edit' && this.tokenExpiredTime < new Date().getTime()) return
      let count = 0
      this.pitchOn[key].map((v, i) => {
        if (v) {
          this.listR[desKey].push(this.listR[key][i - count])
          this.listR[key].splice(i - count, 1)
          count++
        }
      })
      this.pitchOn[key] = []
    },
    // 添加或删除全部R
    addOrRemoveAllR (key, desKey) {
      if (key == 'available') {
        tokenAnalyze('tokenAddAllDevice', '/tkm')
      } else {
        tokenAnalyze('tokenRemoveAllDevice', '/tkm')
      }
      // 过期的token不允许操作设备
      if (this.type == 'edit' && this.tokenExpiredTime < new Date().getTime()) return
      if (this.listR[key].length == 0) return
      this.listR[desKey] = this.listR[desKey].concat(this.listR[key])
      this.listR[key] = []
    },
    // 格式化时间
    formatTime (val) {
      if (val == '') return ''
      let timeObj = Outil.getDateTime(val)
      return timeObj.year + '/' + timeObj.month + '/' + timeObj.day + ' ' + timeObj.hour + ':' + timeObj.min
    },
    // 更改过期时间
    changeExpiredTime (val) {
      if (typeof val == 'object') val = val.getTime()
      const nowData = new Date()
      if (val < nowData.getTime()) {
        this.setExpiredTime = ''
        this.$message.error(this.$t('lang.timeError'))
      }
    },
    // 检测token name是否存在
    checkTokenName () {
      if (this.tokenName == this.oldTokenName) return
      this.oldTokenName = this.tokenName
      let params = {
        name: this.tokenName
      }
      if (this.tokenName.trim() == '') return
      checkName(params).then(res => {
        if (res.status == 200 && res.data.errorCode != '0x0') {
          this.$message({
            message: this.$t('lang.existName'),
            type: 'error'
          })
        }
      }).catch(() => {})
    },
    // 创建token
    createToken () {
      tokenAnalyze('tokenSavePairingDetails', '/tkm')
      if (!this.isSave) return
      this.isSave = false
      setTimeout(() => { this.isSave = true }, 3000)
      this.createTokenPar.name = this.tokenName
      if (this.setExpiredTime != '') this.createTokenPar.expireTime = new Date(this.setExpiredTime).getTime()
      let currentRIds = []
      let bindDevice = []
      let willAddRIds = []
      let willRemoveRIds = []
      this.bindDevice.map(v => {
        if (v) bindDevice.push(v.peerId)
      })
      this.listR.selected.map(v => {
        if (v) currentRIds.push(v.peerId)
      })
      currentRIds.map(v => {
        if (!bindDevice.includes(v)) willAddRIds.push(v)
      })
      bindDevice.map(v => {
        if (!currentRIds.includes(v)) willRemoveRIds.push(v)
      })
      this.createTokenPar.willAddRIds = willAddRIds.join(',')
      this.createTokenPar.willRemoveRIds = willRemoveRIds.join(',')
      if (this.createTokenPar.name.trim() == '') {
        this.createTokenPar.name = ''
        this.$message({
          message: this.$t('lang.tokenNameEmpty'),
          type: 'error'
        })
        return
      }
      if (this.createTokenPar.expireTime == '') {
        this.$message({
          message: this.$t('lang.expiredTimeEmpty'),
          type: 'error'
        })
        return
      }
      if (this.createTokenPar.expireTime <= new Date().getTime()) {
        this.$message({
          message: this.$t('lang.invalidTime'),
          type: 'error'
        })
        return
      }
      this.createTokenPar.autoApprove = !!this.autoApprove
      let url = '/ccp/tvucc-token/tokenCode/saveTokenCode'
      if (this.type == 'edit') {
        url = '/ccp/tvucc-token/tokenCode/updateTokenCode'
        this.createTokenPar.code = this.editItem.code
        if (!this.flag.update) return
        this.flag.update = false
      } else {
        if (!this.flag.add) return
        this.flag.add = false
      }
      this.axios({
        method: 'post',
        url: url,
        data: this.createTokenPar
      }).then(res => {
        if (url == '/ccp/tvucc-token/tokenCode/updateTokenCode') {
          this.flag.update = true
        } else {
          this.flag.add = true
        }
        this.isSave = true
        if (res.status == 200 && res.data.errorCode == '0x0') {
          this.closeCreateBox(true)
        } else {
          Outil.ToTip(res.data.errorCode, 'error', 'lang.operationFailure')
        }
      }).catch(() => {
        if (url == '/ccp/tvucc-token/tokenCode/updateTokenCode') {
          this.flag.update = true
        } else {
          this.flag.add = true
        }
        this.isSave = true
        this.$message({
          message: this.$t('lang.operationFailure'),
          type: 'error'
        })
      })
    },
    checkKey (obj, arr) {
      for (let key in obj) {
        if (arr.includes(key) && obj[key] == '') {
          this.$message({
            message: this.$t('lang.' + key + 'empty'),
            type: 'error'
          })
        }
      }
    }
  }
}
</script>
<style lang='less' scoped>
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background: #aaa;
  opacity: 0.7;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 5px;
  background: none;
}
.create_token {
  .forbid {
    cursor: not-allowed !important;
  }
  p {
    margin-bottom: 0;
  }
  input {
    outline: none;
  }
  // width: 680px;
  width: 750px;
  // height: 835px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: 0px 0px 15px #100400;
  background: #252525;
  padding: 20px 20px 40px 20px;
  text-align: left;
  .title {
    color: #fff;
    font-size: 0.2rem;
    border-bottom: 2px solid #444;
    font-weight: bold;
    text-indent: 0.16rem;
    margin-top: 9px;
    padding-bottom: 14px;
    // height: 60px;
    // line-height: 60px;
    i {
      font-size: 28px;
      font-weight: normal;
      cursor: pointer;
      margin-top: -5px;
    }
  }
  .content {
    padding: 0.3rem 0.25rem 0 0.25rem;
  }
  .create_item {
    margin-bottom: 0.15rem;
    p {
      font-size: 0.16rem;
      margin-bottom: 0.05rem;
    }
    ::v-deep .el-input input {
      height: 46px;
      line-height: 46px;
      font-size: 14px;
    }
    input {
      width: 100%;
      height: 0.46rem;
      line-height: 0.46rem;
      background-color: #444;
      border-radius: 6px;
      font-size: 0.14rem;
      color: #fff;
      border: 1px solid #353535;
      padding-left: 0.1rem;
    }
    ::v-deep .el-input__inner::-webkit-input-placeholder {
      color: #585858;
    }
    .data_icon {
      color: #eee;
      font-size: 23px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    .date_selection {
      height: 0.46rem;
      // min-width: 160px;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      ::v-deep .el-input .el-input__inner {
        border: none;
        background: transparent;
        cursor: pointer;
        color: transparent;
      }
      ::v-deep .el-input__icon {
        display: none;
      }
      ::v-deep .el-date-editor.el-input,
      ::v-deep .el-date-editor.el-input__inner {
        width: 100%;
      }
    }
  }
  .list {
    // width: 225px;
    width: 40%;

    .search_box {
      height: 30px;
      border-radius: 6px;
      border: 1px solid #33ab4f;
      font-size: 12px;
      // margin-top: 8px;
      margin-bottom: 15px;
      input {
        height: 90%;
        width: 87%;
        text-indent: 10px;
        color: #fff;
        background-color: #252525;
        border: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .icon {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
      }
    }
    .tr {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      cursor: pointer;
      position: relative;
    }
    .gray {
      background-color: #373737;
    }
    .pitch_on {
      background-color: #26ade4 !important;
    }
    .list_title {
      color: #0df10e;
      background-color: #000000;
      font-weight: bold;
      height: 32px;
      line-height: 32px;
    }
    .list_content {
      height: 270px;
      background-color: #1e1e1e;
      overflow-y: scroll;
      .checkbox {
        margin: 0;
        position: relative;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        display: inline-block;
        width: 15px;
        height: 15px;
        color: #fff;
        border-radius: 4px;
        padding-top: 3px;
        padding-left: 1px;
        background: #fff;
      }
      .checkbox.ischecked {
        background: springgreen;
      }
    }
    .th,
    .td {
      // width: 100px;
      width: 50%;
      padding-left: 10px;
      height: 32px;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tds {
      width: calc(50% - 15px);
    }
  }
  .btns {
    // width: 140px;
    width: 20%;

    margin-top: 100px;
    .btn {
      width: 80%;
      margin-top: 30px;
      margin-left: 10%;
      cursor: pointer;
      border: 1px solid #0f620a;
      background-color: #0c6;
      color: #fff;
      height: 0.3rem;
      line-height: 0.3rem;
      min-width: 0.76rem;
      font-size: 0.12rem;
      border-radius: 0.04rem;
      padding: 0 0.05rem;
    }
    .btnBlack {
      background: #252525;
      color: #666666;
      border: 1px solid #666666;
    }
  }
  .footer {
    margin-top: 16px;
    text-align: center;
    .btn {
      margin-right: 0.48rem;
      height: 0.4rem;
      width: 1rem;
      // border: 1px solid #0F620A;
      // background-color: #0c6;
      color: #fff;
      font-size: 12px;
      cursor: pointer;
      border: 1px solid #524c4c;
      background-color: #a0a0a0;
    }
    .btn.gray {
      // border: 1px solid #524C4C;
      // background-color: #a0a0a0;
      border: 1px solid #0f620a;
      background-color: #0c6;
      color: #fff;
      margin-right: 0;
    }
  }
}
</style>
