import { token } from '@/assets/js/eventAnalyze.config.js'
export function tokenAnalyze (str, path) {
  if (path != '/tkm') return
  try {
    let eventName = token[str]()
    gtag('event', eventName) // 谷歌统计
  } catch (err) {
    console.log('The Google analysis has not been loaded yet')
  }
}
