<template>
  <el-dialog
    :title='$t("lang.editDevice")'
    :visible.sync="dialogVisible"
    width="500px"
    :before-close="handleClose"
    :destroy-on-close='true'>
    <p class='device_name'>{{$t('lang.deviceName')}}</p>
    <el-input
      v-model="deviceName"
      @input="inputName"
      v-filter-special-char="{changeVal,regular:this.regular}"></el-input>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        type="primary"
        v-repeat-click='{event:saveEdit}'>{{$t('lang.save')}}</el-button>
      <el-button @click="handleClose">{{$t('lang.cancel')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import toTips from '@/assets/js/toTipsObj'
import { updateDeviceName } from '@/service/token'

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editDevice: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      preEditName: '',
      deviceName: '',
      peerId: '',
      regular: /["'<>%;)(&+]/
    }
  },
  watch: {
    dialogVisible (val) {
      if (!val) return
      this.deviceName = this.editDevice.name
      this.preEditName = this.editDevice.name
      this.peerId = this.editDevice.peerId
    }
  },
  methods: {
    changeVal (val) {
      this.val = this.val ? val : ''
    },
    // 关闭弹窗
    handleClose () {
      this.deviceName = this.preEditName
      this.$emit('update:dialogVisible', false)
    },
    // 保存修改
    saveEdit () {
      if (typeof this.deviceName == 'string' && !this.deviceName.trim()) {
        this.$message.error(this.$t('lang.set_name'))
        return
      }
      if (!this.deviceName) {
        this.$message.error(this.$t('lang.set_name'))
        return
      }
      let param = {
        name: encodeURIComponent(this.deviceName),
        peerId: this.peerId,
        type: 'T',
        updateType: '0'
      }
      updateDeviceName(param).then(res => {
        if (res.data.errorCode == '0x0') {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.handleClose()
          this.$emit('updateList', 1)
          return
        }
        this.$message({
          message: this.$t(toTips[res.data.errorCode] || 'lang.enditFailed'),
          type: 'error'
        })
      }).catch(() => {})
    },
    // 字符串转字节
    stringToByte (str) {
      str = str.toString()
      var bytes = []
      var len, c
      len = str.length
      for (var i = 0; i < len; i++) {
        c = str.charCodeAt(i)
        if (c >= 0x010000 && c <= 0x10FFFF) {
          bytes.push(((c >> 18) & 0x07) | 0xF0)
          bytes.push(((c >> 12) & 0x3F) | 0x80)
          bytes.push(((c >> 6) & 0x3F) | 0x80)
          bytes.push((c & 0x3F) | 0x80)
        } else if (c >= 0x000800 && c <= 0x00FFFF) {
          bytes.push(((c >> 12) & 0x0F) | 0xE0)
          bytes.push(((c >> 6) & 0x3F) | 0x80)
          bytes.push((c & 0x3F) | 0x80)
        } else if (c >= 0x000080 && c <= 0x0007FF) {
          bytes.push(((c >> 6) & 0x1F) | 0xC0)
          bytes.push((c & 0x3F) | 0x80)
        } else {
          bytes.push(c & 0xFF)
        }
        if (bytes.length > 15) {
          return str.slice(0, i)
        }
      }
      return str
    },
    // 编辑name
    inputName () {
      this.deviceName = this.stringToByte(this.deviceName)
    }
  }
}
</script>
<style lang="less" scoped>
p {
  margin-bottom: 0;
}
::v-deep .el-dialog {
  text-align: left;
  .el-dialog__header {
    padding-left: 16px;
    .el-dialog__headerbtn .el-icon-close {
      transform: rotate(45deg);
    }
    .el-dialog__headerbtn .el-icon-close:hover {
      transform: rotate(45deg);
    }
    .el-dialog__headerbtn .el-icon-close:before {
      content: '\E602';
    }
  }
  .el-dialog__body {
    font-size: 16px;
  }
  .el-input .el-input__inner {
    border: none;
    background: rgba(68, 68, 68, 1);
    border-radius: 4px;
  }
  .el-dialog__footer {
    text-align: center;
    padding: 24px 0 32px;
  }
  .el-button {
    width: 120px;
    height: 40px;
  }
}
.device_name {
  margin-bottom: 5px;
}
</style>
