import axios from '@/assets/js/axiosExtend.js'

/**
 * 删除token列表
 * @param {*} code
 */
export const deleteToken = code => {
  return axios({
    url: '/ccp/tvucc-token/tokenCode/invalidateTokenCode',
    method: 'post',
    data: { code }
  })
}
/**
 * 处理anywhere
 * @param {*} params
 */
export const handleAnywhere = params => {
  return axios({
    url: '/ccp/tvucc-token/tokenPair/confirm',
    method: 'post',
    data: params
  })
}

/**
 * 清除消息提示
 * @param {*} code
 */
export const clearNotify = code => {
  return axios({
    url: `/ccp/tvucc-token/tokenDevice/consumeAlert?code=${code}`,
    method: 'get'
  })
}

/**
 * 获取关联的R
 * @param {*} params
 */
export const getCorrelateR = params => {
  return axios({
    url: '/ccp/tvucc-token/tokenDevice/correlateR',
    method: 'post',
    data: params
  })
}

/**
 * 获取未关联的R
 * @param {*} params
 */
export const getNotAssociatedR = params => {
  return axios({
    url: '/ccp/tvucc-token/tokenDevice/availableR',
    method: 'post',
    data: params
  })
}

/**
 * 获取未关联的R
 * @param {*} params
 */
export const checkName = params => {
  return axios({
    url: '/ccp/tvucc-token/tokenCode/checkName',
    method: 'post',
    data: params
  })
}

/**
 * 更新设备名称
 * @param {*} params
 */
export const updateDeviceName = params => {
  return axios({
    url: '/ccp/tvucc-device/device/editDeviceName',
    method: 'post',
    data: params
  })
}

/**
 * 发送邮件
 * @param {*} params
 */
export const sendEmail = params => {
  return axios({
    url: '/ccp/tvucc-token/tokenCode/sendTokeneMail',
    method: 'post',
    data: params
  })
}

/**
 * 发送邮件
 * @param {*} params
 */
export const getUserList = params => {
  return axios({
    url: '/ccp/tvucc-token/tokenCode/getUserListByParentUserId',
    method: 'post',
    data: params
  })
}

/**
 * 保存分享
 * @param {*} params
 */
export const saveShare = params => {
  return axios({
    url: '/ccp/tvucc-token/tokenCode/updateTokenShare',
    method: 'post',
    data: params
  })
}

/**
 * 获取token列表
 * @param {*} params
 */
export const findToken = params => {
  return axios({
    url: '/ccp/tvucc-token/tokenCode/findToken',
    method: 'post',
    data: params
  })
}

/**
 * 保存分享
 * @param {*} params
 */
export const getShareList = code => {
  return axios({
    url: `/ccp/tvucc-token/tokenCode/getShareUserList/${code}`,
    method: 'get'
  })
}
